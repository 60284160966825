@import '@objectedge/pecai-storefront-ds/src/styles/variables';

.container {
  display: flex;
  flex-direction: column;
}

.main {
  flex-grow: 1;

  @include media-breakpoint-down(lg) {
    margin-top: 0;
  }
}
