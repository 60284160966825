@import '@objectedge/pecai-storefront-ds/src/styles/variables';

.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
}

.main {
  flex-grow: 1;
  @include media-breakpoint-down(lg) {
    margin-top: 0;
  }
}

.clipContent {
  clip-path: inset(0 0 0 0);
}
