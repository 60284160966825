@import '@objectedge/pecai-storefront-ds/src/styles/variables';

.couponButtonImage {
  position: fixed;
  right: 9px;
  bottom: 8px;
  width: 110px;
  height: 110px;
  cursor: pointer;
  z-index: 1000;
  border-radius: 100%;

  @include media-breakpoint-down(sm) {
    width: 92px;
    height: 92px;
  }
}

.couponButtonImageHover {
  position: fixed;
  right: 17px;
  bottom: 13px;
  width: 102px;
  height: 102px;
  cursor: pointer;
  z-index: 1000;
  border-radius: 100%;

  @include media-breakpoint-down(sm) {
    width: 84px;
    height: 84px;
  }
}
