@import '@objectedge/pecai-storefront-ds/src/styles/variables';

.main {
  border-left: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px 50px;
  z-index: 10000;

  @include media-breakpoint-down(sm) {
    padding: 10px 16px;
  }

  .paragraphFormat {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 32px;
    text-align: center;

    p {
      display: inline;
    }
  }

  .noMarginBottom {
    margin-bottom: 0;
  }

  .inputCouponStringCopy {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    .couponInput {
      width: 27rem;

      @include media-breakpoint-down(sm) {
        width: 100%;
      }

      div > input {
        text-align: center;
        font-size: 20px;
        padding-left: 2.5rem;
      }
    }
  }

  .successAlert {
    top: 2rem;
    position: fixed;
    z-index: 1000;
  }
}
